/* Write here your custom javascript codes */


$(document).ready(function(){
    $('.sponsor-row').slick({
        autoplay: true,
        autoplaySpeed: 1000,
        infinite: true,
        slidesToShow: 4,
        arrows: false
    });
});
